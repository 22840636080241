import FormColors from "constants/FormColors";

function BlHorizontalSeparator({width}) {
    return (
        <div style={{
            borderBottom: `1px solid ${FormColors.TEXT}`,
            width
        }}></div>
    )
}

export default BlHorizontalSeparator;