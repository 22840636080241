import Modal from 'react-bootstrap/Modal';

function BlModalBody({children}) {
    return (
        <Modal.Body>
            {children}
        </Modal.Body>
    )

}

export default BlModalBody;