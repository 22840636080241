import { Container, Row } from "react-bootstrap";
import BlPageHeader from "components/common/BlPageHeader";
import UsageCharts from "./components/UsageCharts";

function StatisticsContent() {
    return (
        <Container>
            <BlPageHeader>Statistics</BlPageHeader>
            <Row>
                <UsageCharts/>
            </Row>
        </Container>
    )
}

export default StatisticsContent;