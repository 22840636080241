import Actions from "store/Actions";

const initialItems = Array(0).fill(null).map((_, i) => ({
    id: `${i + 1}`,
    name: `User ${i}`,
    email: `user${i}@gmail.com`,
    admin: Math.random() > 0.7,
    allowedRoomIds: [],
    allowedEquipmentIds: []
}))

const initialUsers = {
    items: initialItems
}

function users(state = initialUsers, action) {
    switch(action.type) {
        case Actions.SET_USERS:
            const nonCompleteUsers = state.items.filter(user => !(user.name && user.email));
            const extractedUsers = action.items.map(user => ({
                ...user,
                allowedRoomIds: JSON.parse(user.allowedRoomIds),
                allowedEquipmentIds: JSON.parse(user.allowedEquipmentIds)
            }))
            return {
                items: [
                    ...nonCompleteUsers,
                    ...extractedUsers
                ]
            };
        case Actions.ADD_USER: {
            return {
                items: [
                    action.item,
                    ...state.items
                ]
            };
        }
        case Actions.EDIT_USER: {
            const items = state.items.map(item => {
                if (item.id === action.item.id) {
                    return action.item;
                }

                return item;
            });

            return { items };
        }
        case Actions.DELETE_USER: {
            const items = state.items.filter(item => item !== action.item);

            return { items };
        }
        default:
            return state;
    }
}

export default users;
