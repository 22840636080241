import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { useRooms, useAccountInfo } from "store/selectors";
import RoomView from './component/RoomView';
import AddRoomButton from './component/AddRoomButton';
import BlPageHeader from 'components/common/BlPageHeader';
import Actions from 'store/Actions';
import { fetchRooms } from 'api/rooms';

function RoomsContent() {
    const dispatch = useDispatch();
    const rooms = useRooms();
    const accountInfo = useAccountInfo();

    useEffect(() => {
        fetchRooms(accountInfo)
            .then(data => dispatch({ type: Actions.SET_ROOMS, items: data.rooms }))
     // eslint-disable-next-line
    }, []);

    return (
        <Container>
            <BlPageHeader>Rooms</BlPageHeader>
            <Row>
                {rooms.items.map(room => 
                    <Col key={`room-view-${room.id}`} sm="12" md="6" lg="4" style={{marginBottom: '1em'}}>
                        <RoomView room={room}/>
                    </Col>
                )}
            </Row>
            <AddRoomButton/>
        </Container>
    )
}

export default RoomsContent;