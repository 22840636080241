import FeatureDescription from "./FeatureDescription";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const features = [
    {
        title: 'Sample Tracking',
        description: 'Manage the tracking of samples throughout the laboratory workflow, including sample intake, processing, analysis, and storage.'
    },
    {
        title: 'Inventory Management',
        description: 'Track and manage laboratory supplies, reagents, and equipment inventory to ensure efficient usage and timely restocking.'
    },
    {
        title: 'Experiment Management',
        description: 'Organize and document experimental protocols, parameters, and results for easy reference and reproducibility.'
    },
    {
        title: 'Data Management',
        description: 'Store, organize, and analyze experimental data generated from various instruments and assays in a centralized database.'
    },
    {
        title: 'Workflow Automation',
        description: 'Automate routine laboratory tasks and workflows to minimize manual errors, improve efficiency, and save time.'
    },
    {
        title: 'Compliance Management',
        description: 'Ensure compliance with regulatory standards and protocols, such as Good Laboratory Practices (GLP) and Health and Safety regulations.'
    },
    {
        title: 'Collaboration Tools',
        description: 'Facilitate collaboration among laboratory members by providing tools for data sharing, communication, and project management.'
    },
    {
        title: 'Equipment Maintenance',
        description: 'Schedule and track maintenance tasks for laboratory equipment to ensure proper functioning and longevity.'
    },
    {
        title: 'Quality Control',
        description: 'Implement quality control measures to monitor and evaluate the accuracy, precision, and reliability of laboratory processes and results.'
    },
    {
        title: 'Reporting and Analytics',
        description: 'Generate customizable reports and analytics to track key performance indicators, monitor trends, and make data-driven decisions.'
    },
    {
        title: 'Security and Access Control',
        description: 'Implement robust security measures to safeguard sensitive data and control access to laboratory resources based on roles and permissions.'
    },
    {
        title: 'Audit Trail',
        description: 'Maintain a comprehensive audit trail of all activities and changes within the system for traceability and accountability purposes.'
    }
];


function FeatureDescriptions() {
    return (
        <Row>
            {features.map(feature =>
                <Col md="6" lg="4">
                    <FeatureDescription title={feature.title} description={feature.description}/>
                </Col>
                )}
        </Row>
    );
}

export default FeatureDescriptions;