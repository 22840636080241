import { combineReducers } from '@reduxjs/toolkit';
import modals from './modals';
import accountInfo from './accountInfo';
import rooms from './rooms';
import equipment from './equipment';
import bookings from './bookings';
import users from './users';
import chartData from './chartData';

const reducer = combineReducers({
    modals,
    accountInfo,
    rooms,
    equipment,
    bookings,
    users,
    chartData
});

export default reducer;