import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';

function BlMultiSelect({ selectedIds, setSelectedIds, options, style }) {
    const selectedOptions = options
        .filter(option => selectedIds.includes(option.id));

    function handleValueChange(event) {
        const newSelectedIds = event.target.value.map(option => option.id);
        setSelectedIds(newSelectedIds);
    }

    return (
        <Select
            multiple
            variant="standard"
            onChange={handleValueChange}
            style={{width: '100%', ...style}}
            value={selectedOptions}
            renderValue={(selectedOptions) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selectedOptions.map((option) => (
                        <Chip key={option.id} label={option.title} />
                    ))}
                </Box>
                )}>
            {options.map(option =>
                <MenuItem key={option.id} value={option}>
                    {option.title}
                </MenuItem>)}
        </Select>
    )
}

export default BlMultiSelect;