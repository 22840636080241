import { Container, Row, Col } from "react-bootstrap";
import { useUsers } from "store/selectors";

import UserView from "./UserView";


function AdminSettingsEditor() {
    const users = useUsers();

    return (
        <Container>
            <Row>
                {users.items.map(user => 
                    <Col key={`user-view-${user.id}`} sm="12" md="6" style={{marginBottom: '1em'}}>
                        <UserView user={user}/>
                    </Col>
                )}
            </Row>
        </Container>
    )
}

export default AdminSettingsEditor;