import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import AccountSettingsContent from "./accountSettings/AccountSettingsContent";
import RoomsContent from "./rooms/RoomsContent";
import EquipmentContent from "./equipment/EquipmentContent";
import StartWorkingContent from "./startWorking/StartWorkingContent";
import StatisticsContent from "./statistics/StatisticsContent";
import AdminSettingsContent from "./adminSettings/AdminSettingsContent";
import SupportContent from "./support/SupportContent";

const router = createBrowserRouter([
    {
        path: "/accountSettings",
        element: <AccountSettingsContent/>
    },
    {
        path: "/rooms",
        element: <RoomsContent/>
    },
    {
        path: "/equipment",
        element: <EquipmentContent/>
    },
    {
        path: "/",
        element: <StartWorkingContent/>
    },
    {
        path: "/statistics",
        element: <StatisticsContent/>
    },
    {
        path: "/adminSettings",
        element: <AdminSettingsContent/>
    },
    {
        path: "/support",
        element: <SupportContent/>
    }
]);


function WorkspacePages() {
    return (
        <RouterProvider router={router} />
    )
}

export default WorkspacePages;