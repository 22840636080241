function BlNavSection(props) {
    return (
        <div style={{
            marginTop: '1em',
            marginBottom: '1em'
        }}>
            {props.children}
        </div>
    )
}

export default BlNavSection;