import { Container, Row } from "react-bootstrap";
import BlPageHeader from "components/common/BlPageHeader";

function SupportContent() {
    return (
        <Container>
            <BlPageHeader>Support</BlPageHeader>
            <Row>

            </Row>
        </Container>
    )
}

export default SupportContent;