import Nav from 'react-bootstrap/Nav';

import Colors from 'constants/Colors';

function BlNavLink(props) {
    return <Nav.Link 
        {...props}
        style={{
            ...props.style,
            color: Colors.DARK_GREEN,
            marginLeft: '1em',
            marginRight: '1em'
        }}/>
} 

export default BlNavLink;

