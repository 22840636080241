import axios from 'axios';
import Urls from 'constants/Urls';
import { createRequestContext } from './utils';

export async function createEquipment(accountInfo, {id, title, description, rooms}) {
    let formData = new FormData();
    formData.append('id', id);
    formData.append('title', title);
    formData.append('description', description);
    formData.append('rooms', JSON.stringify(rooms));
    return axios.postForm(`${Urls.DJANGO}/api/${accountInfo.info.id}/equipment`, formData, createRequestContext(accountInfo))
        .then(response => response.data);
}

export async function updateEquipment(accountInfo, {id, title, description, rooms}) {
    let formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('rooms', JSON.stringify(rooms));
    return axios.postForm(`${Urls.DJANGO}/api/${accountInfo.info.id}/equipment/${id}`, formData, createRequestContext(accountInfo));
}

export async function deleteEquipment(accountInfo, {id}) {
    return axios.delete(`${Urls.DJANGO}/api/${accountInfo.info.id}/equipment/${id}`, createRequestContext(accountInfo));
}

export async function fetchEquipment(accountInfo) {
    return axios.get(`${Urls.DJANGO}/api/${accountInfo.info.id}/equipment`, createRequestContext(accountInfo))
        .then(result => result.data);
}