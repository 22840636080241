import { useDispatch } from "react-redux";

import AddComponentButton from "components/common/buttons/AddComponentButton";
import Actions from "store/Actions";
import { useAccountInfo } from "store/selectors";


function AddToolButton() {
    const dispatch = useDispatch();
    const accountInfo = useAccountInfo();

    function openAddToolModal() {
        dispatch({ type: Actions.OPEN_ADD_TOOL_MODAL });
    }

    return accountInfo.admin && <AddComponentButton onClick={openAddToolModal}/>
}

export default AddToolButton;