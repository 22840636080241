import Form from 'react-bootstrap/Form';
import FormColors from 'constants/FormColors';

function FormGroup(props) {
    return (
        <Form.Group
            controlId={props.controlId}
            style={{
                padding: '0.2em',
                paddingLeft: '2em',
                paddingRight: '2em',
                borderRadius: '0.5em',
                margin: '0.5em',
                backgroundColor: FormColors.SECONDARY
            }}
        >
            <Form.Label style={{
                marginBottom: 0
            }}>
                <span style={{
                    color: FormColors.TEXT,
                    opacity: '60%'
                }}>
                    {props.label}
                </span>
            </Form.Label>
            <Form.Control type={props.type} onChange={props.onChange} as={props.as} rows={props.rows} value={props.value} disabled={props.disabled} style={{
                backgroundColor: FormColors.SECONDARY,
                border: 'none',
                boxShadow: 'none',
                color: FormColors.TEXT
            }}/>
        </Form.Group>
    )
}

export default FormGroup;