import Form from 'react-bootstrap/Form';
import FormColors from 'constants/FormColors';

function BlFormGroup(props) {
    return (
        <Form.Group
            controlId={props.controlId}
            style={{
                padding: '0.2em',
                paddingLeft: '2em',
                paddingRight: '2em',
                borderRadius: '0.5em',
                margin: '0.5em',
                backgroundColor: FormColors.SECONDARY,
                ...props.style
            }}
        >
           {props.children}
        </Form.Group>
    )
}

export default BlFormGroup;