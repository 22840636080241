import Actions from "store/Actions";

function indexById(items) {
    return new Map(items.map(item => [item.id, item]))
}

function index(items) {
    return {
        id: indexById(items)
    }
}

const initialItems = Array(0).fill(null).map((_, i) => ({
    id: `${i + 1}`,
    title: `Room ${i + 1}`,
    description: `Description of the room ${i + 1}`,
    booked: Math.random() > 0.5
}))

const initialRooms = {
    indexes: index(initialItems),
    items: initialItems
};

function rooms(state=initialRooms, action) {
    switch (action.type) {
        case Actions.SET_ROOMS:
            return {
                items: action.items,
                indexes: index(action.items)
            }
        case Actions.ADD_ROOM: {
            const items = [
                ...state.items,
                action.item
            ];
            const indexes = index(items);

            return { items, indexes };
        }
        case Actions.EDIT_ROOM: {
            const items = state.items.map(item => {
                if (item.id === action.item.id) {
                    return action.item;
                }

                return item;
            });
            const indexes = index(items);

            return { items, indexes };
        }
        case Actions.DELETE_ROOM: {
            const items = state.items.filter(room => room !== action.item)
            const indexes = index(items);

            return { items, indexes };
        }
        default:
            return state;
    }
}

export default rooms;