import Form from 'react-bootstrap/Form';
import FormColors from 'constants/FormColors';

function BlFormSelect({ onChange,  options, value}) {
    return (
        <Form.Select value={value} onChange={onChange} style={{
            backgroundColor: FormColors.SECONDARY,
            border: 'none',
            boxShadow: 'none',
            color: FormColors.TEXT
        }}>
            {
                options.map(opt => 
                    <option value={opt.id}>{opt.text}</option>)
            }
        </Form.Select>
    )
} 

export default BlFormSelect;