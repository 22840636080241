import { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useDispatch } from 'react-redux';

import BlErrorAlert from 'components/common/BlErrorAlert';
import BlFormPrimaryButton from 'components/common/modals/BlFormPrimaryButton';
import FormGroup from 'components/common/modals/FormGroup';
import { useAccountInfo } from 'store/selectors';
import Actions from 'store/Actions';
import { updatePassword } from 'api/accountInfo';

function AccountSettingsEditor() {
    const dispatch = useDispatch();
    const accountInfo = useAccountInfo();
    const [company, setCompany] = useState(accountInfo.info.company);
    const [department, setDepartment] = useState(accountInfo.info.department);
    const [team, setTeam] = useState(accountInfo.info.team);
    const [password, setPassword] = useState()
    const [confirmPassword, setConfirmPassword] = useState();
    const [errorMessage, setErrorMessage] = useState();

    function findError() {
        if (!company) {
            return "Company name cannot be empty";
        }

        if (!department) {
            return "Department cannot be empty";
        }

        if (!team) {
            return "Team cannot be empty";
        }

        if (password !== confirmPassword) {
            return "Password and confirmation must match"
        }
    }

    function saveAccountInfo() {
        const errorMessageMaybe = findError();
        setErrorMessage(errorMessageMaybe);
        if (errorMessageMaybe) {
            return;
        }

        const accountInfo = {
            company,
            department,
            team,
            password,
            confirmPassword
        }


        updatePassword(password)
            .then(() => alert('Updated password successfully'))
            .catch(() => alert('Failed to update password'));

        dispatch({ type: Actions.UPDATE_ACCOUNT_INFO, accountInfo })
    }

    function handleCompanyChange(event) {
        setCompany(event.target.value)
    }

    function handleDepartmentChange(event) {
        setDepartment(event.target.value)
    }

    function handlePasswordChange(event) {
        setPassword(event.target.value)
    }

    function handleConfirmPasswordChange(event) {
        setConfirmPassword(event.target.value)
    }

    function handleTeamChange(event) {
        setTeam(event.target.value)
    }

    return (
        <Container>
            <Form>
                <Row>
                    <Col sm="6">
                        <FormGroup controlId="company" label="Company" type="text" value={company} onChange={handleCompanyChange} disabled={true}/>
                    </Col>
                    <Col sm="6">
                        <FormGroup controlId="email" label="Email address" type="email" value={accountInfo.info.email} disabled={true}/>
                    </Col>
                    <Col sm="6">
                        <FormGroup controlId="department" label="Department" type="text" value={department} onChange={handleDepartmentChange} disabled={true}/>
                    </Col>
                    <Col sm="6">
                        <FormGroup controlId="password" label="Password" type="password" value={password} onChange={handlePasswordChange}/>
                    </Col>
                    <Col sm="6">
                        <FormGroup controlId="team" label="Team" type="text" value={team} onChange={handleTeamChange} disabled={true}/>
                    </Col>
                    <Col sm="6">
                        <FormGroup controlId="confirmPassword" label="Confirm password" type="password" value={confirmPassword} onChange={handleConfirmPasswordChange}/>
                    </Col>
                </Row>
                <BlErrorAlert errorMessage={errorMessage}/>
                <Row>
                    <Col sm="12" style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '2em'
                    }}>
                        <BlFormPrimaryButton onClick={saveAccountInfo}>
                            Update
                        </BlFormPrimaryButton>
                    </Col>
                </Row>
            </Form>
        </Container>
    );
}

export default AccountSettingsEditor;