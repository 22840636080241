import { useDispatch } from "react-redux";

import AddComponentButton from "components/common/buttons/AddComponentButton";
import Actions from "store/Actions";


function AddBookingButton() {
    const dispatch = useDispatch();

    function openAddBookingModal() {
        dispatch({ type: Actions.OPEN_ADD_BOOKING_MODAL });
    }

    return <AddComponentButton onClick={openAddBookingModal}/>
}

export default AddBookingButton;