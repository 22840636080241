import { Button } from 'react-bootstrap';

import Colors from 'constants/Colors';
import arrow from 'img/arrow_counterclockwise.svg';

function ResetComponentButton({ onClick }) {
    return (
        <Button className='btn-close' onClick={onClick} style={{
            background: 'none',
            border: 'none',
            boxShadow: 'none'
        }}>
            <img src={arrow} alt="ResetObject" style={{width: '1em', color: Colors.DARK_GREEN}}/>
        </Button>
    )
}

export default ResetComponentButton;