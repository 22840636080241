import axios from 'axios';
import Urls from 'constants/Urls';
import { createRequestContext } from './utils';


axios.defaults.withCredentials = true


export async function createRoom(accountInfo, {id, title, description}) {
    let formData = new FormData();
    formData.append('id', id);
    formData.append('title', title);
    formData.append('description', description);
    return axios.postForm(`${Urls.DJANGO}/api/${accountInfo.info.id}/room`, formData, createRequestContext(accountInfo))
        .then(response => response.data);
};


export async function updateRoom(accountInfo, {id, title, description}) {
    let formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    return axios.postForm(`${Urls.DJANGO}/api/${accountInfo.info.id}/room/${id}`, formData, createRequestContext(accountInfo));
}


export async function deleteRoom(accountInfo, {id}) {
    return axios.delete(`${Urls.DJANGO}/api/${accountInfo.info.id}/room/${id}`, createRequestContext(accountInfo));
}


export async function fetchRooms(accountInfo) {
    return axios.get(`${Urls.DJANGO}/api/${accountInfo.info.id}/room`, createRequestContext(accountInfo))
        .then(response => response.data);
}
