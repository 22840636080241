import Button from 'react-bootstrap/Button';

import FormColors from 'constants/FormColors';

function BlFormPrimaryButton(props) {
    return (
        <Button {...props} style={{
            ...props.style,
            color: FormColors.TEXT,
            borderColor: FormColors.PRIMARY,
            backgroundColor: 'transparent',
            width: '20em'
        }}>
            <i>
                {props.children}
            </i>
        </Button>
    )
}

export default BlFormPrimaryButton;