import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { Form, Container, Row, Col } from 'react-bootstrap';

import BlItemCard from "components/common/BlItemCard";
import Actions from "store/Actions";
import { useAccountInfo, useEquipment, useRooms } from 'store/selectors';
import BlMultiSelect from 'components/common/BlMultiSelect';
import BlToggleablePasswordInput from 'components/common/BlToggleablePasswordInput';

import { createUser, deleteUser, updateUser } from 'api/users';


function arrayEquals(arr1, arr2) {
    return JSON.stringify(arr1) === JSON.stringify(arr2);
}

function UserView({ user }) {
    const dispatch = useDispatch();
    const accountInfo = useAccountInfo();
    const rooms = useRooms();
    const equipment = useEquipment();
    const [name, setName] = useState(user.name);
    const [email, setEmail] = useState(user.email);
    const [admin, setAdmin] = useState(user.admin);
    const [allowedRoomIds, setAllowedRoomIds] = useState(user.allowedRoomIds);
    const [allowedEquipmentIds, setAllowedEquipmentIds] = useState(user.allowedEquipmentIds);
    const [password, setPassword] = useState();
    const [passwordConfirmation, setPasswordConfirmation] = useState();

    const initialFieldsAreValid = user.name && user.email;

    function hasChangedFields() {
        return (
            (name !== user.name) ||
            (email !== user.email) ||
            (admin !== user.admin) ||
            !arrayEquals(allowedRoomIds, user.allowedRoomIds) ||
            !arrayEquals(allowedEquipmentIds, user.allowedEquipmentIds)
        )
    }

    function hasValidFields() {
        const hasValidFieldsForUpdate = ( name && email );

        return initialFieldsAreValid
            ? hasValidFieldsForUpdate
            : hasValidFieldsForUpdate && password && passwordConfirmation && (password === passwordConfirmation);
    }

    function resetView() {
        setName(user.name);
        setEmail(user.email);
        setAdmin(user.admin);
        setAllowedRoomIds(user.allowedRoomIds);
        setAllowedEquipmentIds(user.allowedEquipmentIds);
    }

    function saveUser() {
        const updatedUser = {
            ...user,
            name,
            email,
            password,
            admin,
            allowedRoomIds: [
                ...allowedRoomIds
            ],
            allowedEquipmentIds: [
                ...allowedEquipmentIds
            ]
        }

        if (!initialFieldsAreValid) {
            createUser(accountInfo, updatedUser)
                .then(() => dispatch({ type: Actions.EDIT_USER, item: updatedUser }));
        } else {
            updateUser(accountInfo, updatedUser)
                .then(() => dispatch({ type: Actions.EDIT_USER, item: updatedUser }));
        }
    }

    function handleNameChange(event) {
        setName(event.target.value);
    }

    function handleEmailChange(event) {
        setEmail(event.target.value);
    }

    function handleAdminChange(event) {
        setAdmin(!!event.target.checked)
    }

    function handlePasswordChange(event) {
        setPassword(event.target.value);
    }

    function handlePasswordConfirmationChange(event) {
        setPasswordConfirmation(event.target.value);
    }

    function deleteItem() {
        if (initialFieldsAreValid) {
            deleteUser(accountInfo, user)
                .then(result => dispatch({ type: Actions.DELETE_USER, item: user}));
        } else {
            dispatch({ type: Actions.DELETE_USER, item: user});
        }
    }

    return (
        <BlItemCard
            item={user}
            title={user.name}
            deleteItem={deleteItem}
            onSave={hasChangedFields() && hasValidFields() && saveUser}
            onReset={hasChangedFields() && resetView}
            isError={!initialFieldsAreValid}>
            <Form>
                <Container>
                    <Form.Group>
                        <Row style={{marginBottom: '1em'}}>
                            <Col sm="3">
                                <Form.Label>Name</Form.Label>
                            </Col>
                            <Col sm="9">
                                <Form.Control type="text" value={name} onChange={handleNameChange}/>
                            </Col>
                        </Row>
                        <Row style={{marginBottom: '1em'}}>
                            <Col sm="3">
                                <Form.Label>Email</Form.Label>
                            </Col>
                            <Col sm="9">
                                <Form.Control type="email" value={email} onChange={handleEmailChange} disabled={user.email}/>
                            </Col>
                        </Row>
                        <Row style={{marginBottom: '1em'}}>
                            <Col sm="3"/>
                            <Col sm="9">
                                <Form.Check
                                    id={`is-user-${user.id}-admin`}
                                    type="checkbox"
                                    label="Admin"
                                    checked={admin}
                                    onChange={handleAdminChange}/>
                            </Col>
                        </Row>
                        {!initialFieldsAreValid &&
                            <>
                                <Row style={{marginBottom: '1em'}}>
                                    <Col sm="3">
                                        <Form.Label>Password</Form.Label>
                                    </Col>
                                    <Col sm="9">
                                        <BlToggleablePasswordInput value={password} onChange={handlePasswordChange}/>
                                    </Col>
                                </Row>
                                <Row style={{marginBottom: '1em'}}>
                                    <Col sm="3">
                                        <Form.Label>Confirm</Form.Label>
                                    </Col>
                                    <Col sm="9">
                                        <BlToggleablePasswordInput value={passwordConfirmation} onChange={handlePasswordConfirmationChange}/>
                                    </Col>
                                </Row>
                            </>
                        }
                        {!admin && 
                            <>
                                <Row style={{marginBottom: '1em'}}>
                                    <Col sm="3">
                                        <Form.Label>Rooms</Form.Label>
                                    </Col>
                                    <Col sm="9">
                                        <BlMultiSelect
                                            selectedIds={allowedRoomIds}
                                            setSelectedIds={setAllowedRoomIds}
                                            options={rooms.items}/>
                                    </Col>
                                </Row>
                                <Row style={{marginBottom: '1em'}}>
                                    <Col sm="3">
                                        <Form.Label>Equipment</Form.Label>
                                    </Col>
                                    <Col sm="9">
                                        <BlMultiSelect
                                            selectedIds={allowedEquipmentIds}
                                            setSelectedIds={setAllowedEquipmentIds}
                                            options={equipment.items}/>
                                    </Col>
                                </Row>
                            </>
                        }
                    </Form.Group>
                </Container>
            </Form>
        </BlItemCard>
    );
}

export default UserView;