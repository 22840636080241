import Modal from 'react-bootstrap/Modal';

function BlModalHeader(props) {
    return (
        <Modal.Header style={{
            display: 'flex',
            justifyContent: 'center',
            borderBottom: 'none',
            marginTop: '2em'
        }}>
            <Modal.Title>
                {props.children}
            </Modal.Title>
        </Modal.Header>
    );
}

export default BlModalHeader;