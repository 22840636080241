import { useDispatch } from 'react-redux';
import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import BlModal from 'components/common/modals/BlModal';
import BlModalBody from 'components/common/modals/BlModalBody';
import BlModalFooter from 'components/common/modals/BlModalFooter';
import BlFormPrimaryButton from 'components/common/modals/BlFormPrimaryButton';
import BlFormSecondaryButton from 'components/common/modals/BlFormSecondaryButton';
import BlHorizontalSeparator from 'components/common/BlHorizontalSeparator';
import FormGroup from 'components/common/modals/FormGroup';
import { useSignInModalStatus } from 'store/selectors';
import Actions from 'store/Actions';
import { login } from 'api/accountInfo';


function SignInModal() {
    const status = useSignInModalStatus();
    const dispatch = useDispatch();
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();

    function handleCloseModal() {
        dispatch({ type: Actions.CLOSE_SIGN_IN_MODAL })
    }

    function handleSignIn() {
        login(username, password)
            .then(accountInfo => {
                dispatch({ type: Actions.SET_ACCOUNT_INFO, accountInfo });
                handleCloseModal();
            })
            .catch(error => alert('Sign in attempt failed, probably username or/and password are incorrect'))
    }

    function handleRegisterATeam() {
        handleCloseModal();
        dispatch({ type: Actions.OPEN_SIGN_UP_MODAL });
    }

    function handleUsernameChange(event) {
        setUsername(event.target.value);
    }

    function handlePasswordChange(event) {
        setPassword(event.target.value);
    }

    return (
        <BlModal title="Sign In" show={status.open} onHide={handleCloseModal}>
            <BlModalBody>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Form style={{
                        minWidth: '30em'
                    }}>
                        <Row>
                            <Col sm="12">
                                <FormGroup controlId="email" label="Email address" type="text" value={username} onChange={handleUsernameChange}/>
                            </Col>
                            <Col sm="12">
                                <FormGroup controlId="password" label="Password" type="password" value={password} onChange={handlePasswordChange}/>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </BlModalBody>
            <BlModalFooter>
                <BlFormPrimaryButton onClick={handleSignIn}>
                    Sign In
                </BlFormPrimaryButton>
                <BlHorizontalSeparator width="25em"/>
                <BlFormSecondaryButton onClick={handleRegisterATeam}>
                    REGISTER A TEAM
                </BlFormSecondaryButton>
            </BlModalFooter>
        </BlModal>
    )
}

export default SignInModal;