import MainPageView from 'components/pages/main/view/MainPageView';
import H1 from "components/text/H1";
import P from "components/text/P";


function TeamView() {
    return (
        <MainPageView id="team">
            <div>
                <H1>Team</H1>
                <P>
                    Created by Anastasiia Chermoshentseva<br/>
                    BC-03 FBMI Student<br/>
                    For Diploma Work
                </P>
            </div>
        </MainPageView>
    );
}

export default TeamView;