import AboutView from "./view/about/AboutView";
import FeaturesView from "./view/features/FeaturesView";
import PricingView from "./view/pricing/PricingView";
import TeamView from "./view/team/TeamView";
import MainPageFooter from "./MainPageFooter";
import SignInModal from './modals/SignInModal';
import SignUpModal from './modals/SignUpModal';
import BlNavbar from "./BlNavbar";


function MainPageContent() {
    return (
        <div>
            <BlNavbar/>
            <div style={{paddingBottom: 0, marginBottom: 0 }}>
                <SignInModal/>
                <SignUpModal/>
                <AboutView/>
                <FeaturesView/>
                <PricingView/>
                <TeamView/>
                <MainPageFooter/>
            </div>
        </div>
    );
}

export default MainPageContent;