const Actions = {
    OPEN_SIGN_IN_MODAL: 'OPEN_SIGN_IN_MODAL',
    CLOSE_SIGN_IN_MODAL: 'CLOSE_SIGN_IN_MODAL',
    OPEN_SIGN_UP_MODAL: 'OPEN_SIGN_UP_MODAL',
    CLOSE_SIGN_UP_MODAL: 'CLOSE_SIGN_UP_MODAL',
    OPEN_ADD_ROOM_MODAL: 'OPEN_ADD_ROOM_MODAL',
    CLOSE_ADD_ROOM_MODAL: 'CLOSE_ADD_ROOM_MODAL',
    OPEN_ADD_TOOL_MODAL: 'OPEN_ADD_TOOL_NODAL',
    CLOSE_ADD_TOOL_MODAL: 'CLOSE_ADD_TOOL_MODAL',
    OPEN_EDIT_TOOL_MODAL: 'OPEN_EDIT_TOOL_MODAL',
    CLOSE_EDIT_TOOL_MODAL: 'CLOSE_EDIT_TOOL_MODAL',
    OPEN_EDIT_ROOM_MODAL: 'OPEN_EDIT_ROOM_MODAL',
    CLOSE_EDIT_ROOM_MODAL: 'CLOSE_EDIT_ROOM_MODAL',
    OPEN_ADD_BOOKING_MODAL: 'OPEN_ADD_BOOKING_MODAL',
    CLOSE_ADD_BOOKING_MODAL: 'CLOSE_ADD_BOOKING_MODAL',
    OPEN_EDIT_BOOKING_MODAL: 'OPEN_EDIT_BOOKING_MODAL',
    CLOSE_EDIT_BOOKING_MODAL: 'CLOSE_EDIT_BOOKING_MODAL',
    OPEN_ADD_USER_MODAL: 'OPEN_ADD_USER_MODAL',
    CLOSE_ADD_USER_MODAL: 'CLOSE_ADD_USER_MODAL',
    OPEN_EDIT_USER_MODAL: 'OPEN_EDIT_USER_MODAL',
    CLOSE_EDIT_USER_MODAL: 'CLOSE_EDIT_USER_MODAL',
    SET_ACCOUNT_INFO: 'SET_ACCOUNT_INFO',
    UPDATE_ACCOUNT_INFO: 'UPDATE_ACCOUNT_INFO',
    LOG_OUT: 'LOG_OUT',
    ADD_ROOM: 'ADD_ROOM',
    EDIT_ROOM: 'EDIT_ROOM',
    DELETE_ROOM: 'DELETE_ROOM',
    SET_ROOMS: 'SET_ROOMS',
    ADD_EQIPMENT: 'ADD_EQUIPMENT',
    EDIT_EQUIPMENT: 'EDIT_EQUIPMENT',
    DELETE_EQUIPMENT: 'DELETE_EQUIPMENT',
    SET_EQUIPMENT: 'SET_EQUIPMENT',
    CREATE_BOOKING: 'CREATE_BOOKING',
    DELETE_BOOKING: 'RELEASE_BOOKING',
    SET_BOOKINGS: 'SET_BOOKINGS',
    SET_USERS: 'SET_USERS',
    ADD_USER: 'ADD_USER',
    EDIT_USER: 'EDIT_USER',
    DELETE_USER: 'DELETE_USER',
    SET_CHART_DATA: 'SET_CHART_DATA'
};

export default Actions;