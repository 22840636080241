import MainPageView from 'components/pages/main/view/MainPageView';
import H1 from "components/text/H1";

function PricingView() {
    return (
        <MainPageView id="pricing">
            <div>
                <H1>Pricing</H1>
                <p>Great news! Our web app is currently available for free. Take advantage of this limited-time offer to access all features without any cost. Sign up now and unlock the full potential of our platform.</p>
            </div>
        </MainPageView>
    );
}

export default PricingView;