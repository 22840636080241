import Colors from "../../constants/Colors";

function P(props) {
    return (
        <p {...props} style={{
            color: Colors.DARK_GREEN,
            ...props.style
        }}></p>
    )
};

export default P;