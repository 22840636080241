import { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {v4 as uuidv4} from 'uuid';

import BlErrorAlert from "components/common/BlErrorAlert";
import BlFormPrimaryButton from "components/common/modals/BlFormPrimaryButton";
import BlModal from "components/common/modals/BlModal";
import BlModalBody from "components/common/modals/BlModalBody";
import BlModalFooter from "components/common/modals/BlModalFooter";
import FormGroup from "components/common/modals/FormGroup";

function RoomModal({
    show,
    initialId,
    initialTitle,
    initialDescription,
    initialBooked,
    addRoom,
    closeAction
}) {
    const [title, setTitle] = useState(initialTitle);
    const [description, setDescription] = useState(initialDescription);
    const [errorMessage, setErrorMessage] = useState();
    const dispatch = useDispatch();

    useEffect(() => {
        setTitle(initialTitle);
        setDescription(initialDescription);
    }, [initialTitle, initialDescription]);

    function handleCloseModal() {
        dispatch({ type: closeAction });
    }

    function findError() {
        if (!title) {
            return "Room title cannot be empty";
        }

        if (!description) {
            return "Room description cannot be empty";
        }
    }

    function handleAddRoom(event) {
        event.preventDefault();
        const errorMessageMaybe = findError();
        setErrorMessage(errorMessageMaybe);

        if (errorMessageMaybe) {
            return;
        }

        const room = {
            id: initialId || uuidv4(),
            title,
            description,
            booked: initialBooked || false
        };

        handleCloseModal();
        addRoom(room);
    }

    function handleTitleChange(event) {
        setTitle(event.target.value);
    }

    function handleDescriptionChange(event) {
        setDescription(event.target.value);
    }

    return (
        <BlModal title="Add room" show={show} onHide={handleCloseModal}>
            <Form style={{
                minWidth: '30em'
            }} onSubmit={handleAddRoom}>
                <BlModalBody>
                    <Row>
                        <Col sm="12">
                            <FormGroup controlId="title" label="Title" type="text" value={title} onChange={handleTitleChange}/>
                        </Col>
                        <Col sm="12">
                            <FormGroup controlId="description" label="Description" as="textarea" rows="5" value={description} onChange={handleDescriptionChange}/>
                        </Col>
                    </Row>
                    <BlErrorAlert errorMessage={errorMessage}/>
                </BlModalBody>
                <BlModalFooter>
                    <BlFormPrimaryButton type="submit">
                        Add
                    </BlFormPrimaryButton>
                </BlModalFooter>
            </Form>            
        </BlModal>
    )
}

export default RoomModal;