import Actions from "store/Actions";

function indexById(items) {
    return new Map(items.map(item => [item.id, item]))
}

function indexByRoomId(items) {
    const roomIdAndItemTuples = items.flatMap(item => item.rooms.map(room => [room.id, item]));
    const grouped = Map.groupBy(roomIdAndItemTuples, i => i[0]);
    const groupedArray = Array.from(grouped).map(([key, value]) => [key, value.map(val => val[1])]);
    return new Map(groupedArray);
}

function index(items) {
    return {
        id: indexById(items),
        roomId: indexByRoomId(items)
    }
}

function rand(max) {
    return Math.floor(Math.random() * max)
}

const initialItems = Array(0).fill(null).map((_, i) => ({
    id: `${i}`,
    title: `Tool ${i}`,
    description: `Description of the tiil ${i}`,
    rooms:  [...Array(rand(5))].map(ignored => ({
        id: `${rand(40)}`,
        count: rand(3 + 1)
    }))
}));

const initialEquipment = {
    items: initialItems,
    indexes: index(initialItems)
}

function equipment(state=initialEquipment, action) {
    switch (action.type) {
        case Actions.SET_EQUIPMENT:
            return {
                items: action.items,
                indexes: index(action.items)
            };
        case Actions.ADD_EQIPMENT: {
            const items = [
                ...state.items,
                action.item
            ];
            const indexes = index(items);

            return { items, indexes };
        }
        case Actions.EDIT_EQUIPMENT: {
            const items = state.items.map(tool => {
                if (action.item.id === tool.id) {
                    return action.item;
                }

                return tool;
            });

            const indexes = index(items);

            return { items, indexes };
        }
        case Actions.DELETE_EQUIPMENT: {
            const items = state.items.filter(tool => tool !== action.item)
            const indexes = index(items);

            return { items, indexes }
        }
        default:
            return state;
    }
}

export default equipment;