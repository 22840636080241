import {
    Row,
    Col,
    Alert
} from 'react-bootstrap';


function BlErrorAlert({ errorMessage }) {
    return (
        <div>
            {
                errorMessage && (
                    <Row>
                        <Col sm="12">
                            <Alert variant="warning">
                                {errorMessage}
                            </Alert>
                        </Col>
                    </Row>
                )
            }
        </div>
    )
}

export default BlErrorAlert