import Card from 'react-bootstrap/Card';

import EditComponentButton from './buttons/EditComponentButton';
import SaveComponentButton from './buttons/SaveComponentButton';
import ResetComponentButton from './buttons/ResetComponentButton';
import DeleteComponentButton from './buttons/DeleteComponentButton';
import MarkComponentAsCompleteButton from './buttons/MarkComponentAsCompleteButton';

function BlItemCard({item, title, description, deleteItem, editItem, onSave, onComplete, onReset, isError, children}) {
    const border = isError ? '2px solid red' : 'none';

    return (
        <Card style={{ width: '100%', height: '100%', border: border }}>
            <Card.Header>
                <Card.Title>      
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between"
                    }}>
                        <span>{title}</span>
                        <div>
                            {editItem && (<EditComponentButton onClick={editItem}/>)}
                            {onSave && (<SaveComponentButton onClick={onSave}/>)}
                            {onComplete && (<MarkComponentAsCompleteButton onClick={onComplete}/>)}
                            {onReset && (<ResetComponentButton onClick={onReset}/>)}
                            {deleteItem && <DeleteComponentButton onClick={deleteItem}/>}
                        </div>
                    </div>
                </Card.Title>
            </Card.Header>
            <Card.Body>
                {description && 
                    <Card.Text>
                        {description}
                    </Card.Text>
                }
                {children}
            </Card.Body>
        </Card>
    )
}

export default BlItemCard;