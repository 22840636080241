import Actions from "store/Actions"

const initialBookings = {
    rooms: [
        // {
        //     id: 'esrer',
        //     title: "For very important stuff",
        //     description: "Desc 1",
        //     roomId: "1",
        //     equipmentIds: [
        //         "1", "4", "2"
        //     ]
        // },
        // {
        //     id: 'sdvfdgfhgh',
        //     title: "Another booking",
        //     description: "Another booking",
        //     roomId: "4",
        //     equipmentIds: [
        //         "1", "4", "2"
        //     ]
        // }
    ]
}

function bookings(state=initialBookings, action) {
    switch(action.type) {
        case Actions.SET_BOOKINGS:
            const sortedItems = action.items.sort((a, b) => {
                if (a.endedAt && !b.endedAt) {
                    return 1;
                }

                if (b.endedAt && !a.endedAt) {
                    return -1;
                }

                if (a.startedAt && b.startedAt) {
                    return new Date(a.startedAt) < new Date(b.startedAt) ? 1 : -1;
                }

                return 0;
            });
            return {
                rooms: sortedItems
            };
        case Actions.CREATE_BOOKING:
            return {
                ...state,
                rooms: [
                    action.item,
                    ...state.rooms
                ]
            };
        case Actions.DELETE_BOOKING:
            const updatedRooms = state.rooms
                .filter(roomId => action.roomId !== roomId)
            return {
                ...state,
                rooms: updatedRooms
            };
        default:
            return state;
    }
}

export default bookings;