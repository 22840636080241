import Button from 'react-bootstrap/Button';
import Colors from 'constants/Colors';

import check from 'img/check_lg.svg';

function MarkComponentAsCompleteButton({onClick}) {
    return (
        <Button className='btn-close' onClick={onClick} style={{
            background: 'none',
            border: 'none',
            boxShadow: 'none'
        }}>
           <img src={check} alt="MarkObjectAsComplete" style={{width: '1em', color: Colors.DARK_GREEN}}/>
        </Button>
    );
}

export default MarkComponentAsCompleteButton;