import MainPageView from 'components/pages/main/view/MainPageView';
import FeatureDescriptions from './FeatureDescriptions';

function FeaturesView() {
    return (
        <MainPageView id="features">
            <FeatureDescriptions/>
        </MainPageView>
    );
}

export default FeaturesView;