import Colors from "constants/Colors";

function FeatureDescription({title, description}) {
    return (
        <p style={{color: Colors.DARK_GREEN}}>
            <b>{title}:</b> {description}
        </p>
    )
};

export default FeatureDescription;