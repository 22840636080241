import WorkspaceContainer from "./WorkspaceContainer";
import WorkspaceBody from "./WorkspaceBody";
import WorkspaceSidebar from "./WorkspaceSidebar";
import WorkspacePages from "./pages/WorkspacePages";

function WorkspacePageContent() {
    return (
        <WorkspaceContainer>
            <WorkspaceSidebar/>
            <WorkspaceBody>
                <WorkspacePages/>
            </WorkspaceBody>
        </WorkspaceContainer>
    )
}

export default WorkspacePageContent;