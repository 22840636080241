import Modal from 'react-bootstrap/Modal';

import BlModalHeader from './BlModalHeader';

function BlModal({
    title,
    show,
    onHide,
    children
}) {
    return (
        <Modal show={show} onHide={onHide} size="lg">
            <BlModalHeader>{title}</BlModalHeader>
            {children}
        </Modal>
    );
}

export default BlModal