import { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {v4 as uuidv4} from 'uuid';

import Colors from 'constants/Colors';
import Button from 'react-bootstrap/Button';

import BlErrorAlert from "components/common/BlErrorAlert";
import DeleteComponentButton from "components/common/buttons/DeleteComponentButton";
import BlFormControl from "components/common/modals/BlFormControl";
import BlFormGroup from "components/common/modals/BlFormGroup";
import BlFormLabel from "components/common/modals/BlFormLabel";
import BlFormPrimaryButton from "components/common/modals/BlFormPrimaryButton";
import BlFormSelect from "components/common/modals/BlFormSelect";
import BlModal from "components/common/modals/BlModal";
import BlModalBody from "components/common/modals/BlModalBody";
import BlModalFooter from "components/common/modals/BlModalFooter";
import FormGroup from "components/common/modals/FormGroup";
import plusCircle from 'img/plus_circle.svg';
import { useRooms } from "store/selectors";


function AddRoomButton({ onClick }) {
    return (
        <Row>
            <Col sm="12" style={{
                display: "flex",
                justifyContent: "center"
            }}>
            <Button style={{
                background: 'none',
                border: 'none',
                boxShadow: 'none'
            }} onClick={onClick}>
                <img src={plusCircle} alt="AddObject" style={{width: '1em', color: Colors.DARK_GREEN}}/>
            </Button>
            </Col>
        </Row>
    )
}

function RoomInput({ roomInfo, roomsOptions, handleRoomChange, handleItemCountChange, deleteRoomInfo}) {
    return (
        <Row>
            <Col>
                <BlFormSelect
                    value={roomInfo.id}
                    options={roomsOptions}
                    onChange={handleRoomChange}/>
            </Col>
            <Col>
                <BlFormControl
                    type="number"
                    min="1"
                    value={roomInfo.count}
                    placeholder="Number of items in the room"
                    onChange={handleItemCountChange}/>
            </Col>
            <DeleteComponentButton onClick={deleteRoomInfo}/>
        </Row>
    )
}

function ToolModal({
    initialId,
    initialTitle,
    initialDescription,
    initialRoomInfos,
    handleTool,
    show,
    closeAction
}) {
    const [title, setTitle] = useState(initialTitle);
    const [description, setDescription] = useState(initialDescription);
    const [roomInfos, setRoomInfos] = useState(initialRoomInfos);
    const [errorMessage, setErrorMessage] = useState();
    const rooms = useRooms();
    const dispatch = useDispatch();

    useEffect(() => {
        setTitle(initialTitle);
        setDescription(initialDescription);
        setRoomInfos(initialRoomInfos);
    }, [initialTitle, initialDescription, initialRoomInfos])

    function handleCloseModal() {
        dispatch({ type: closeAction });
    }

    function findError() {
        if (!title) {
            return "Room title cannot be empty";
        }

        if (!description) {
            return "Room description cannot be empty";
        }

        if (roomInfos.some(info => !info.id)) {
            return "One or more room ids are empty";
        }

        if (roomInfos.some(info => !info.count)) {
            return "Item counts for one or more rooms are empty";
        }
    }

    function handleAddTool(event) {
        event.preventDefault();
        const errorMessageMaybe = findError();
        setErrorMessage(errorMessageMaybe);
        
        if (errorMessageMaybe) {
            return;
        }

        const roomInfosCopy = roomInfos.map(info => ({ ...info }))

        const tool = {
            id: initialId || uuidv4(),
            title,
            description,
            rooms: roomInfosCopy
        };

        handleTool(tool);
        handleCloseModal();
    }

    function handleTitleChange(event) {
        setTitle(event.target.value);
    }

    function handleDescriptionChange(event) {
        setDescription(event.target.value);
    }

    function handleRoomChange(event, roomInfo) {
        roomInfo.id = event.target.value;
        setRoomInfos(roomInfos);
    }

    function handleItemCountChange(event, roomInfo) {
        roomInfo.count = parseInt(event.target.value);
        setRoomInfos(roomInfos);
    }

    const roomsOptions = rooms.items.map(room => ({
        id: room.id,
        text: room.title
    }))

    function handleAddRoomInfo() {
        setRoomInfos([
            ...roomInfos,
            {
                id: roomsOptions.length && roomsOptions[0].id,
                count: undefined
            }
        ])
    }

    function handleDeleteRoomInfo(roomInfo) {
        const updatedRoomInfos = roomInfos.filter(info => info !== roomInfo);
        setRoomInfos(updatedRoomInfos);
    }

    return (
        <BlModal title="Add tool" show={show} onHide={handleCloseModal}>
            <Form style={{
                minWidth: '30em'
            }} onSubmit={handleAddTool}>
                <BlModalBody>
                    <Row>
                        <Col sm="12">
                            <FormGroup controlId="title" label="Title" type="text" value={title} onChange={handleTitleChange}/>
                        </Col>
                        <Col sm="12">
                            <FormGroup controlId="description" label="Description" as="textarea" rows="5" value={description} onChange={handleDescriptionChange}/>
                        </Col>
                        <Col sm="12">
                            <BlFormGroup>
                                <BlFormLabel>Rooms</BlFormLabel>
                                {
                                    roomInfos.map(roomInfo => {
                                        return (
                                            <RoomInput
                                                roomInfo={roomInfo}
                                                roomsOptions={roomsOptions}
                                                handleRoomChange={event => handleRoomChange(event, roomInfo)}
                                                handleItemCountChange={event => handleItemCountChange(event, roomInfo)}
                                                deleteRoomInfo={event => handleDeleteRoomInfo(roomInfo)} />
                                        );
                                    })
                                }
                                <AddRoomButton onClick={handleAddRoomInfo}/>
                            </BlFormGroup>
                        </Col>
                    </Row>
                    <BlErrorAlert errorMessage={errorMessage}/>
                </BlModalBody>
                <BlModalFooter>
                    <BlFormPrimaryButton type="submit">
                        Add
                    </BlFormPrimaryButton>
                </BlModalFooter>
            </Form>            
        </BlModal>
    )
}

export default ToolModal;