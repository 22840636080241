import { Button } from 'react-bootstrap';

import Colors from 'constants/Colors';
import floppy from 'img/floppy.svg';

function SaveComponentButton({ onClick }) {
    return (
        <Button className='btn-close' onClick={onClick} style={{
            background: 'none',
            border: 'none',
            boxShadow: 'none'
        }}>
            <img src={floppy} alt="SaveObject" style={{width: '1em', color: Colors.DARK_GREEN}}/>
        </Button>
    )
}

export default SaveComponentButton;