import Form from 'react-bootstrap/Form';
import FormColors from 'constants/FormColors';

function BlFormControl(props) {
    return (
        <Form.Control {...props} style={{
            backgroundColor: FormColors.SECONDARY,
            border: 'none',
            boxShadow: 'none',
            color: FormColors.TEXT
        }}/>
    )
}

export default BlFormControl;