import { configureStore } from '@reduxjs/toolkit';
import reducer from './reducers/reducer';
import { initialAccountInfo } from './reducers/accountInfo';

const ACCOUNT_INFO = 'accountInfo';

const accountInfo =  localStorage.getItem(ACCOUNT_INFO) 
? JSON.parse(localStorage.getItem(ACCOUNT_INFO))
: initialAccountInfo;

const preloadedState = {
  modals: {
    signIn: {
      status: {
        open: false
      }
    },
    signUp: {
      status: {
        open: false
      }
    }
  },
  accountInfo
}

const store = configureStore({
  reducer,
  preloadedState
});

store.subscribe(() => {
  localStorage.setItem(ACCOUNT_INFO, JSON.stringify(store.getState().accountInfo))
  if (accountInfo.sessionId) {
    // document.cookie = `sessionid=${accountInfo.sessionId};path=/;`;
  }
})

export default store;
