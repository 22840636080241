import Modal from 'react-bootstrap/Modal';

function BlModalFooter({children}) {
    return (
        <Modal.Footer style={{
            display: 'flex',
            flexDirection: 'column',
            borderTop: 'none',
            paddingBottom: '2em'
        }}>
            {children}
        </Modal.Footer>
    )
}

export default BlModalFooter;