import MainPageContent from './components/pages/main/MainPageContent';
import WorkspacePageContent from './components/pages/workplace/WorkspacePageContent';
import { useAccountInfo } from 'store/selectors';

function App() {
  const accountInfo = useAccountInfo(); 

  return (
    <div>
      {!accountInfo.loaded ? <MainPageContent/> : <WorkspacePageContent/>}
    </div>
  );
}

export default App;
