
import { useDispatch } from "react-redux";
import ToolModal from "./ToolModal";
import Actions from "store/Actions";
import { useAccountInfo, useAddToolModalStatus } from "store/selectors";
import { createEquipment } from "api/equipment";

function AddToolModal() {
    const dispatch = useDispatch();
    const status = useAddToolModalStatus();
    const accountInfo = useAccountInfo();

    function addTool(tool) {
        createEquipment(accountInfo, tool)
            .then(result => dispatch({ type: Actions.ADD_EQIPMENT, item: tool }));
    }

    return (
        <ToolModal
            show={status.open}
            initialId={undefined}
            initialTitle={undefined}
            initialDescription={undefined}
            initialRoomInfos={[]}
            handleTool={addTool}
            closeAction={Actions.CLOSE_ADD_TOOL_MODAL}/>
    );
}

export default AddToolModal;