import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { Container, Row } from "react-bootstrap";
import BlPageHeader from "components/common/BlPageHeader";
import BookingsView from "./components/BookingsView";
import AddBookingButton from "./components/AddBookingButton";

import Actions from "store/Actions";
import { useAccountInfo } from "store/selectors";
import { fetchRooms } from "api/rooms";
import { fetchEquipment } from "api/equipment";
import { fetchBookings } from "api/bookings";


function StartWorkingContent() {
    const dispatch = useDispatch();
    const accountInfo = useAccountInfo();

    useEffect(() => {
        Promise.all([fetchBookings(accountInfo), fetchRooms(accountInfo), fetchEquipment(accountInfo)])
            .then(([bookings, rooms, equipment]) => {
                dispatch({ type: Actions.SET_ROOMS, items: rooms.rooms })
                dispatch({ type: Actions.SET_EQUIPMENT, items: equipment.equipment })
                dispatch({ type: Actions.SET_BOOKINGS, items: bookings.bookings });
            });
    // eslint-disable-next-line
    }, []);

    return (
        <Container>
            <BlPageHeader>Start working</BlPageHeader>
            <Row>
                <BookingsView/>
            </Row>
            <AddBookingButton/>
        </Container>
    )
}

export default StartWorkingContent;