import { useDispatch } from 'react-redux';

import Badge from 'react-bootstrap/Badge';

import Actions from "store/Actions";
import BlItemCard from "components/common/BlItemCard";
import { useAccountInfo, useRooms } from 'store/selectors';
import { deleteEquipment } from 'api/equipment';

function ToolView({tool}) {
    const dispatch = useDispatch();
    const accountInfo = useAccountInfo();
    const rooms = useRooms();
    const idToRoomIndex = rooms.indexes.id;

    function deleteItem() {
        deleteEquipment(accountInfo, tool)
            .then(result => dispatch({ type: Actions.DELETE_EQUIPMENT, item: tool}))
    }

    function editItem() {
        dispatch({ type: Actions.OPEN_EDIT_TOOL_MODAL, item: tool })
    }

    return (
        <BlItemCard
            item={tool}
            title={tool.title}
            description={tool.description}
            deleteItem={accountInfo.admin && deleteItem}
            editItem={accountInfo.admin && editItem}>
            {tool.rooms.map(roomInfo => {
                    const room = idToRoomIndex.get(roomInfo.id)
                    return room &&
                        <span key={`room-badge-${tool.id}-${roomInfo.id}`} style={{margin: '0.1em'}}>
                            <Badge db="success">
                                {room.title}:{roomInfo.count}
                            </Badge>
                        </span>
                }
            )}
        </BlItemCard>
    )
}

export default ToolView;