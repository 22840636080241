import axios from 'axios';
import Urls from 'constants/Urls';
import { createRequestContext } from './utils';


export async function createUser(accountInfo, {id, name, email, password, admin, allowedRoomIds, allowedEquipmentIds}) {
    let formData = new FormData();
    formData.append('id', id);
    formData.append('name', name);
    formData.append('email', email);
    formData.append('password', password);
    formData.append('admin', admin ? 'True' : 'False');
    formData.append('allowedRoomIds', JSON.stringify(allowedRoomIds));
    formData.append('allowedEquipmentIds', JSON.stringify(allowedEquipmentIds));
    return axios.postForm(`${Urls.DJANGO}/api/${accountInfo.info.id}/user`, formData, createRequestContext(accountInfo))
        .then(response => response.data);
};

export async function updateUser(accountInfo, {id, name, email, password, admin, allowedRoomIds, allowedEquipmentIds}) {
    let formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('password', password);
    formData.append('admin', admin ? 'True' : 'False');
    formData.append('allowedRoomIds', JSON.stringify(allowedRoomIds));
    formData.append('allowedEquipmentIds', JSON.stringify(allowedEquipmentIds));
    return axios.postForm(`${Urls.DJANGO}/api/${accountInfo.info.id}/user/${id}`, formData, createRequestContext(accountInfo))
        .then(response => response.data);
};

export async function deleteUser(accountInfo, {id}) {
    return axios.delete(`${Urls.DJANGO}/api/${accountInfo.info.id}/user/${id}`, createRequestContext(accountInfo));
}

export async function fetchUsers(accountInfo) {
    return axios.get(`${Urls.DJANGO}/api/${accountInfo.info.id}/user`, createRequestContext(accountInfo))
        .then(response => response.data);
}