import {
    Container,
    Row,
    Col
} from 'react-bootstrap';

import BookingView from './BookingView';
import { useBookings } from "store/selectors";

function BookingsView() {
    const bookings = useBookings();

    return (
        <Container>
            <Row>
                {bookings.rooms.map(bookedRoom => 
                    <Col key={`booking-view-${bookedRoom.id}`} sm="12" md="6" style={{marginBottom: '1em'}}>
                        <BookingView booking={bookedRoom}/>
                    </Col>
                )}
            </Row>
        </Container>
    )
}

export default BookingsView;