import { useDispatch } from "react-redux";
import { useAccountInfo, useEditRoomModal } from "store/selectors";

import Actions from "store/Actions";
import RoomModal from "./RoomModal";
import { updateRoom } from "api/rooms";

function EditRoomModal() {
    const dispatch = useDispatch();
    const editRoomModal = useEditRoomModal();
    const accountInfo = useAccountInfo();

    function addRoom(room) {
        updateRoom(accountInfo, room)
            .then(result => dispatch({ type: Actions.EDIT_ROOM, item: room }));
    }


    return (
        <RoomModal
            show={editRoomModal.status.open}
            initialId={editRoomModal.room.id}
            initialTitle={editRoomModal.room.title}
            initialDescription={editRoomModal.room.description}
            initialBooked={editRoomModal.room.booked}
            addRoom={addRoom}
            closeAction={Actions.CLOSE_EDIT_ROOM_MODAL}/>
    )
}

export default EditRoomModal;