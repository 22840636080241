import { useDispatch } from "react-redux";

import AddComponentButton from "components/common/buttons/AddComponentButton";
import Actions from "store/Actions";
import { useAccountInfo } from "store/selectors";


function AddRoomButton() {
    const dispatch = useDispatch();
    const accountInfo = useAccountInfo();

    function openAddRoomModal() {
        dispatch({ type: Actions.OPEN_ADD_ROOM_MODAL });
    }

    return  accountInfo.admin && <AddComponentButton onClick={openAddRoomModal}/>;
}

export default AddRoomButton;