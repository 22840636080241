import { Container, Row, Col } from "react-bootstrap";
import BlPageHeader from "components/common/BlPageHeader";
import AccountSettingsEditor from "./components/AccountSettingsEditor";
import AddUserButton from "../adminSettings/components/AddUserButton";

function AccountSettingsContent() {
    return (
        <Container>
            <BlPageHeader>Account settings</BlPageHeader>
            <Row>
                <Col sm="12">
                    <AccountSettingsEditor/>
                </Col>
            </Row>
            <AddUserButton/>
        </Container>
    )
}

export default AccountSettingsContent;