import H1 from 'components/text/H1';
import MainPageP from 'components/text/MainPageP';
import MainPageView from 'components/pages/main/view/MainPageView';
import lab from 'img/lab.svg';
import RegisterButton from './RegisterButton';

function AboutView() {
    return (
        <MainPageView id="about">
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '100%',
                width: '100%'
            }}>
                <H1 style={{fontSize: '52px'}}>
                    Built to Optimize, Track to Innovate
                </H1>
                <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                    <div style={{maxWidth: '70%', marginTop: '1em'}}>
                        <MainPageP style={{fontSize: '26px'}}>
                            Built for making precise, informed decisions that drive your business forward:
                            clean, accurate, and dependable product analytics to optimize and innovate.
                        </MainPageP>
                        <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: '3em'}}>
                            <RegisterButton/>
                        </div>
                    </div>
                    <img 
                        style={{
                            position: 'relative',
                            right: '5em',
                            bottom: '5em',
                            zIndex: -1,
                        }}
                        src={lab} alt="Lab"/>
                </div>
            </div>
        </MainPageView>
    );
}

export default AboutView;