import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { Container, Row } from "react-bootstrap";
import BlPageHeader from "components/common/BlPageHeader";
import AdminSettingsEditor from "./components/AdminSettingsEditor";
import AddUserButton from "./components/AddUserButton";

import Actions from "store/Actions";
import { useAccountInfo } from "store/selectors";
import { fetchUsers } from "api/users";
import { fetchRooms } from "api/rooms";
import { fetchEquipment } from "api/equipment";

function AdminSettingsContent() {
    const dispatch = useDispatch();
    const accountInfo = useAccountInfo();

    useEffect(() => {
        fetchUsers(accountInfo)
            .then(data => dispatch({ type: Actions.SET_USERS, items: data.users }));

        fetchRooms(accountInfo)
            .then(data => dispatch({type: Actions.SET_ROOMS, items: data.rooms}));

        fetchEquipment(accountInfo)
            .then(data => dispatch({type: Actions.SET_EQUIPMENT, items: data.equipment}));
     // eslint-disable-next-line
    }, [])

    return (
        <Container>
            <BlPageHeader>Admin settings</BlPageHeader>
            <Row>
                <AdminSettingsEditor/>
            </Row>
            <AddUserButton/>
        </Container>
    )
}

export default AdminSettingsContent;