import { useDispatch } from 'react-redux';
import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { useSignUpModalStatus } from 'store/selectors';
import Actions from 'store/Actions';
import FormGroup from 'components/common/modals/FormGroup';
import BlModal from 'components/common/modals/BlModal';
import BlModalBody from 'components/common/modals/BlModalBody';
import BlModalFooter from 'components/common/modals/BlModalFooter';
import BlFormPrimaryButton from 'components/common/modals/BlFormPrimaryButton';
import BlFormSecondaryButton from 'components/common/modals/BlFormSecondaryButton';
import BlHorizontalSeparator from 'components/common/BlHorizontalSeparator';
import { createAccount } from 'api/accountInfo';


function SignUpModal() {
    const status = useSignUpModalStatus();
    const dispatch = useDispatch();
    const [company, setCompany] = useState();
    const [email, setEmail] = useState();
    const [department, setDepartment] = useState();
    const [team, setTeam] = useState();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();

    function handleCloseModal() {
        dispatch({ type: Actions.CLOSE_SIGN_UP_MODAL })
    }

    function handleSignUp() {
        handleCloseModal();
        dispatch({ type: Actions.OPEN_SIGN_IN_MODAL });
    }

    function handleRegisterATeam() {
        createAccount(company, department, team, email, password)
            .then(accountInfo => {
                dispatch({ type: Actions.SET_ACCOUNT_INFO, accountInfo })
                handleCloseModal();
            });
    }

    function handleCompanyChange(event) {
        setCompany(event.target.value);
    }

    function handleEmailChange(event) {
        setEmail(event.target.value);
    }

    function handleDepartmentChange(event) {
        setDepartment(event.target.value);
    }

    function handleTeamChange(event) {
        setTeam(event.target.value);
    }

    function handlePasswordChange(event) {
        setPassword(event.target.value);
    }

    function handleConfirmPasswordChange(event) {
        setConfirmPassword(event.target.value);
    }

    return (
        <BlModal title="Register a team" show={status.open} onHide={handleCloseModal}>
            <BlModalBody>
                <Form>
                    <Row>
                        <Col sm="6">
                            <FormGroup controlId="company" label="Company" type="text" value={company} onChange={handleCompanyChange}/>
                        </Col>
                        <Col sm="6">
                            <FormGroup controlId="email" label="Email address" type="email" value={email} onChange={handleEmailChange}/>
                        </Col>
                        <Col sm="6">
                            <FormGroup controlId="department" label="Department" type="text" value={department} onChange={handleDepartmentChange}/>
                        </Col>
                        <Col sm="6">
                            <FormGroup controlId="password" label="Password" type="password" value={password} onChange={handlePasswordChange}/>
                        </Col>
                        <Col sm="6">
                            <FormGroup controlId="team" label="Team" type="text" value={team} onChange={handleTeamChange}/>
                        </Col>
                        <Col sm="6">
                            <FormGroup controlId="confirmPassword" label="Confirm password" type="password" value={confirmPassword} onChange={handleConfirmPasswordChange}/>
                        </Col>
                    </Row>
                </Form>
            </BlModalBody>
            <BlModalFooter>
                <BlFormPrimaryButton onClick={handleRegisterATeam}>
                    REGISTER A TEAM
                </BlFormPrimaryButton>
                <BlHorizontalSeparator width="25em"/>
                <BlFormSecondaryButton onClick={handleSignUp}>
                    Sign In
                </BlFormSecondaryButton>
            </BlModalFooter>
        </BlModal>
    )
}

export default SignUpModal;