import Colors from "../../constants/Colors";

function H1(props) {
    return (
        <h1 {...props} style={{
            color: Colors.DARK_GREEN,
            ...props.style
        }}>
            {props.children}
        </h1>
    )
};

export default H1;