import Actions from "store/Actions";

const initialChartData = {
    roomUsage: [],
    toolUsage: []
};

function chartData(state = initialChartData, action) {
    switch(action.type) {
        case Actions.SET_CHART_DATA:
            return action.chartData;
        default:
            return state;
    }
}

export default chartData;
