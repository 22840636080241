import { useDispatch } from "react-redux";
import {v4 as uuidv4} from 'uuid';

import AddComponentButton from "components/common/buttons/AddComponentButton";
import Actions from "store/Actions";
import { useUsers } from "store/selectors";

function isIncompleteUser(user) {
    return !(user.id && user.name && user.email);
}

function AddUserButton() {
    const dispatch = useDispatch();
    const users = useUsers();

    function addEmptyUser() {
        const emptyUser = {
            id: uuidv4(),
            name: '',
            email: '',
            admin: false,
            allowedRoomIds: [],
            allowedEquipmentIds: []
        };

        dispatch({ type: Actions.ADD_USER, item: emptyUser });
        window.scrollTo(0, 0);
    }

    const hasIncompleteUsers = users.items.some(isIncompleteUser)

    return (
        !hasIncompleteUsers &&
            <AddComponentButton onClick={addEmptyUser}/>);
}

export default AddUserButton;