import axios from 'axios';
import {v4 as uuidv4} from 'uuid';
import Urls from 'constants/Urls';

axios.defaults.withCredentials = true

export async function login(username, password) {
    let formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    return axios.postForm(`${Urls.DJANGO}/api/login`, formData, {
        withCredentials: true
    })
        .then(response => {
            document.cookie = `sessionid=${response.data.sessionId};path=/;`;
            return response.data;
        });
}

export async function createAccount(company, department, team, email, password) {
    let formData = new FormData();
    formData.append('id', uuidv4());
    formData.append('company', company);
    formData.append('department', department);
    formData.append('team', team);
    formData.append('email', email);
    formData.append('password', password);
    return axios.postForm(`${Urls.DJANGO}/api/accounts/create`, formData)
        .then(response => response.data);
}

export async function updatePassword(password) {
    let formData = new FormData();
    formData.append('password', password);
    return axios.postForm(`${Urls.DJANGO}/api/accounts/update`, formData);
}
