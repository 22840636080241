import { useSelector } from 'react-redux';

export const useSignInModalStatus = () => useSelector(state => state.modals.signIn.status);
export const useSignUpModalStatus = () => useSelector(state => state.modals.signUp.status);
export const useAddRoomModalStatus = () => useSelector(state => state.modals.addRoom.status);
export const useAddToolModalStatus = () => useSelector(state => state.modals.addTool.status);
export const useAddBookingModalStatus = () => useSelector(state => state.modals.addBooking.status);
export const useEditToolModal = () => useSelector(state => state.modals.editTool);
export const useEditRoomModal = () => useSelector(state => state.modals.editRoom);
export const useEditBookingModal = () => useSelector(state => state.modals.editBooking);
export const useEditUserModal = () => useSelector(state => state.modals.editUser);
export const useAccountInfo = () => useSelector(state => state.accountInfo);
export const useRooms = () => useSelector(state => state.rooms);
export const useEquipment = () => useSelector(state => state.equipment);
export const useBookings = () => useSelector(state => state.bookings);
export const useUsers = () => useSelector(state => state.users);
export const useChartData = () => useSelector(state => state.chartData)
