import { useDispatch } from "react-redux";

import Actions from "store/Actions";
import BookingModal from "./BookingModal";
import { useAccountInfo, useAddBookingModalStatus } from "store/selectors";
import { createBooking } from "api/bookings";
import { fetchRooms } from "api/rooms";


function AddBookingModal() {
    const dispatch = useDispatch();
    const accountInfo = useAccountInfo();
    const status = useAddBookingModalStatus();

    function closeModal() {
        dispatch({ type: Actions.CLOSE_ADD_BOOKING_MODAL });
    }

    function addBooking(booking) {
        createBooking(accountInfo, booking)
            .then(() => {
                fetchRooms(accountInfo)
                    .then(result => dispatch({ type: Actions.SET_ROOMS, items: result.rooms }));

                dispatch({ type: Actions.CREATE_BOOKING, item: booking });
                closeModal();
            })
    }

    return (
        status.open && <BookingModal
            show={status.open}
            initialId={undefined}
            initialTitle={undefined}
            initialDescription={undefined}
            initialRoomId={undefined}
            addBooking={addBooking}
            closeAction={Actions.CLOSE_ADD_BOOKING_MODAL}/>
    )
}

export default AddBookingModal;
