import { BarChart } from '@mui/x-charts/BarChart';
import { useChartData } from 'store/selectors';

import H3 from 'components/text/H3';

function ToolUsageChart() {
    const chartData = useChartData();
    const toolUsage = chartData.toolUsage;
    
    const valueFormatter = (value) => `${value}`;


    return (
        <div>
            <H3>Tool usage</H3>
            <BarChart
                dataset={toolUsage}
                yAxis={[{ scaleType: 'band', dataKey: 'toolTitle' }]}
                series={[{ dataKey: 'usages', label: 'Usages', valueFormatter }]}
                layout='horizontal'
                height={400}/>
        </div>
    );
}

export default ToolUsageChart;