import Button from 'react-bootstrap/Button';
import Colors from 'constants/Colors';

import plusCircle from 'img/plus_circle.svg';

function AddComponentButton({onClick}) {
    return <Button style={{
        position: 'fixed',
        bottom: '2em',
        right: '2em',
        background: 'none',
        border: 'none',
        boxShadow: 'none'
    }} onClick={onClick}>
        <img src={plusCircle} alt="AddObject" style={{width: '3em', color: Colors.DARK_GREEN}}/>
    </Button>;
}

export default AddComponentButton;