import Colors from "../../constants/Colors";

function H3(props) {
    return (
        <h3 {...props} style={{
            color: Colors.DARK_GREEN,
            ...props.style
        }}>
            {props.children}
        </h3>
    )
};

export default H3