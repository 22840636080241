import { useDispatch } from 'react-redux';

import {
    InputGroup,
} from 'react-bootstrap';
import BlItemCard from "components/common/BlItemCard";
import { useAccountInfo, useEquipment, useRooms } from "store/selectors";
import Actions from "store/Actions";
import { completeBooking } from 'api/bookings';
import { fetchBookings } from 'api/bookings';
import { fetchRooms } from 'api/rooms';
import { fetchEquipment } from 'api/equipment';


function ItemInfo({title, value}) {
    return (
        <InputGroup size="sm" style={{width: 'fit-content', margin: '0.2em'}}>
            <InputGroup.Text><strong>{title}</strong></InputGroup.Text>
            <InputGroup.Text>{value}</InputGroup.Text>
        </InputGroup>
    );
}

function formatDateTime(dateTimeString) {
    const date = new Date(dateTimeString);
    const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
    const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
    const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);

    return `${day} ${month} ${year}`;
}


function BookingView({ booking }) {
    const dispatch = useDispatch();
    const accountInfo = useAccountInfo();
    const rooms = useRooms();
    const equipment = useEquipment();
    const idToRoomIndex = rooms.indexes.id;
    const room = idToRoomIndex.get(booking.roomId);

    function handleCompleteBooking() {
        completeBooking(accountInfo, booking)
            .then(result => {
                Promise.all([fetchBookings(accountInfo), fetchRooms(accountInfo), fetchEquipment(accountInfo)])
                    .then(([bookings, rooms, equipment]) => {
                        dispatch({ type: Actions.SET_ROOMS, items: rooms.rooms })
                        dispatch({ type: Actions.SET_EQUIPMENT, items: equipment.equipment })
                        dispatch({ type: Actions.SET_BOOKINGS, items: bookings.bookings });
                    });
            })
    }

    return (
        <BlItemCard
            item={booking}
            title={booking.title}
            description={booking.description}
            onComplete={!booking.endedAt && handleCompleteBooking}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap'
                }}>
                    {booking.startedAt && <ItemInfo title="Started at" value={formatDateTime(booking.startedAt)}/>}
                    {booking.endedAt && <ItemInfo title="Ended at" value={formatDateTime(booking.endedAt)}/>}
                    <ItemInfo title="Room" value={room.title}/>
                    {
                        booking.equipmentIds.map(equipmentId => {
                            const tool = equipment.indexes.id.get(equipmentId);

                            return <ItemInfo  key={`booking-view-equipment-${equipmentId}`} title="Equipment" value={tool.title}/>
                        })
                    }
                </div>
        </BlItemCard>
    )
}

export default BookingView;