import { useState } from 'react';

import {
    Form,
    Button,
    InputGroup
} from 'react-bootstrap';
import Colors from 'constants/Colors';

import eye from 'img/eye.svg';
import eyeSlash from 'img/eye_slash.svg';


function BlToggleablePasswordInput({ value, onChange }) {
    const [show, setShow] = useState(false);
    const inputType = show ? 'text' : 'password';
    const toggleImage = show ? eyeSlash : eye;

    function toggleShow() {
        setShow(!show);
    }

    return (
        <InputGroup>
            <Form.Control type={inputType} value={value} onChange={onChange}/>
            <Button variant='light' onClick={toggleShow} style={{
                zIndex: 0
            }}>
                <img src={toggleImage} alt="TogglePasswordView" style={{width: '1em', color: Colors.DARK_GREEN}}/>
            </Button>
        </InputGroup>
    );
}

export default BlToggleablePasswordInput;
