import { useDispatch } from "react-redux";

import Actions from "store/Actions";
import RoomModal from "./RoomModal";
import { useAddRoomModalStatus } from "store/selectors";
import { useAccountInfo } from "store/selectors";
import { createRoom } from "api/rooms";

function AddRoomModal() {
    const dispatch = useDispatch();
    const status = useAddRoomModalStatus();
    const accountInfo = useAccountInfo();

    function addRoom(room) {
        createRoom(accountInfo, room)
            .then(result => {
                dispatch({ type: Actions.ADD_ROOM, item: room });
            })
        ;
    }

    return (
        <RoomModal
            show={status.open}
            initialId={undefined}
            initialTitle={undefined}
            initialDescription={undefined}
            initialBooked={undefined}
            addRoom={addRoom}
            closeAction={Actions.CLOSE_ADD_ROOM_MODAL}/>
    )
}

export default AddRoomModal;