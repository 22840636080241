import Actions from 'store/Actions';

export const initialAccountInfo = {
    loaded: false,
    sessionId: undefined,
    admin: true,
    info: {
        id: undefined,
        company: 'Test company',
        department: 'Test department',
        team: 'Test team',
        email: 'test@gmail.com',
        password: undefined,
        confirmPassword: undefined
    }
}

export default function accountInfo(state=initialAccountInfo, action) {
    switch (action.type) {
        case Actions.SET_ACCOUNT_INFO:
            return {
                loaded: true,
                sessionId: action.accountInfo.sessionId,
                admin: action.accountInfo.admin,
                info: action.accountInfo.info
            }
        case Actions.UPDATE_ACCOUNT_INFO:
            console.log('Updating account info: ', action.accountInfo);
            return state;
        case Actions.LOG_OUT:
            localStorage.removeItem('accountInfo');
            return initialAccountInfo;
        default:
            return state;
    }
}
