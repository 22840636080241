import Colors from "constants/Colors";

function BlPageHeader(props) {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '2em',
            color: Colors.DARK_GREEN,
            backgroundColor: Colors.BACKGDOUND_GRADIENT_TOP,
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            zIndex: 1
        }}>
            <div style={{
                width: '30%'
            }}></div>
            <h1>{props.children}</h1>
            
        </div>
    )
}

export default BlPageHeader;