import { useDispatch } from 'react-redux';

import Nav from 'react-bootstrap/Nav';

import BlNavLink from 'components/common/BlNavLink';
import BlNavSection from 'components/common/BlNavSection';
import BlHorizontalSeparator from 'components/common/BlHorizontalSeparator';
import BlButton from 'components/common/buttons/BlButton';
import logo from 'img/logo.svg';
import Actions from 'store/Actions';
import { useAccountInfo } from 'store/selectors';


function WorkspaceSidebar() {
    const dispatch = useDispatch();
    const accountInfo = useAccountInfo();
    
    function handleLogOut() {
        dispatch({ type: Actions.LOG_OUT });
        window.location.href = '/';
    }

    return (
        <div className="col-auto col-md-3 col-xl-3 px-sm-3 px-0 bg-light position-fixed" style={{
            zIndex: 2
        }}>
            <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
                <img src={logo} alt="Logo"/>
                <Nav style={{marginTop: '2em'}}>
                    <BlNavSection>
                        <BlNavLink href="/rooms">Rooms</BlNavLink>
                        <BlNavLink href="/equipment">Equipment</BlNavLink>
                    </BlNavSection>
                    <BlHorizontalSeparator width='100%'/>
                    <BlNavSection>
                        <BlNavLink href="/">Start working</BlNavLink>
                        <BlNavLink href="/statistics">Statistics</BlNavLink>
                    </BlNavSection>
                    <BlHorizontalSeparator width='100%'/>
                    <BlNavSection>
                        <BlNavLink href="/accountSettings">Account settings</BlNavLink>
                        {accountInfo.admin && <BlNavLink href="/adminSettings">Admin settings</BlNavLink>}
                        <BlNavLink href="/support">Support</BlNavLink>
                    </BlNavSection>
                </Nav>
                <BlButton style={{width: '100%', marginTop: 'auto', marginBottom: '2em'}} onClick={handleLogOut}>
                    Log out 
                </BlButton>
            </div>
        </div>
    );
}

export default WorkspaceSidebar;