import { useDispatch } from 'react-redux';
import Actions from 'store/Actions';
import BlItemCard from 'components/common/BlItemCard';
import Badge from 'react-bootstrap/Badge';

import { deleteRoom } from 'api/rooms';
import { useAccountInfo } from 'store/selectors';

function RoomView({room}) {
    const dispatch = useDispatch();
    const accountInfo = useAccountInfo();

    function deleteItem() {
        deleteRoom(accountInfo, room)
            .then(result => dispatch({ type: Actions.DELETE_ROOM, item: room}));
    }

    function editItem() {
        dispatch({ type: Actions.OPEN_EDIT_ROOM_MODAL, item: room })
    }

    return (
        <BlItemCard
            item={room}
            title={room.title}
            description={room.description}
            deleteItem={accountInfo.admin && deleteItem}
            editItem={accountInfo.admin && editItem}>
            {
                room.booked
                ? <Badge bg="danger">Booked</Badge>
                : <Badge bg="success">Available</Badge>
            }
        </BlItemCard>
    )
}

export default RoomView;