import Form from 'react-bootstrap/Form';
import FormColors from 'constants/FormColors';

function BlFormLabel(props) {
    return (
        <Form.Label style={{
            marginBottom: 0,
            ...props.style
        }}>
            <span style={{
                color: FormColors.TEXT,
                opacity: '60%'
            }}>
                {props.children}
            </span>
        </Form.Label>
    )
}

export default BlFormLabel;