import Button from 'react-bootstrap/Button';

import Colors from '../../../constants/Colors';

function BlButton(props) {
    return <Button
    {...props}
        style={{
            backgroundColor: Colors.DARK_GREEN,
            borderColor: Colors.DARK_GREEN,
            paddingTop: '1em',
            paddingBottom: '1em',
            paddingLeft: '2em',
            paddingRight: '2em',
            borderRadius: '0.2em',
            ...props.style,
        }}
    />
}

export default BlButton;