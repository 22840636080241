import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Col, Form } from "react-bootstrap";
import {v4 as uuidv4} from 'uuid';

import BlErrorAlert from "components/common/BlErrorAlert";
import BlModal from "components/common/modals/BlModal";
import BlModalBody from "components/common/modals/BlModalBody";
import BlModalFooter from "components/common/modals/BlModalFooter";
import BlFormPrimaryButton from "components/common/modals/BlFormPrimaryButton";
import FormGroup from "components/common/modals/FormGroup";
import BlFormGroup from "components/common/modals/BlFormGroup";
import BlFormLabel from "components/common/modals/BlFormLabel";
import BlFormSelect from "components/common/modals/BlFormSelect";
import BlMultiSelect from "components/common/BlMultiSelect";
import { useRooms, useEquipment } from "store/selectors";


function BookingModal({
    show,
    initialId,
    initialTitle,
    initialDescription,
    initialRoomId,
    addBooking,
    closeAction
}) {
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState();
    const [title, setTitle] = useState(initialTitle);
    const [description, setDescription] = useState(initialDescription);
    const rooms = useRooms();
    const equipment = useEquipment();

    const nonBookedRoomOptions = rooms.items
        .filter(room => !room.booked || room.id === initialRoomId)
        .map(room => ({
            id: room.id,
            text: room.title
        }));

    const [roomId, setRoomId] = useState(nonBookedRoomOptions[0] && nonBookedRoomOptions[0].id);
    const [equipmentIds, setEquipmentIds] = useState([]);

    useEffect(() => {
        setTitle(initialTitle);
        setDescription(initialDescription);
    }, [initialTitle, initialDescription, initialRoomId]);
    

    function findError() {
        if (!title) {
            return "Title cannot be empty";
        }

        if (!description) {
            return "Description cannot be empty";
        }

        if (!roomId) {
            return "Room cannot be empty";
        }
    }

    function handleCloseModal() {
        dispatch({ type: closeAction });
    }

    function handleAddBooking(event) {
        event.preventDefault();
        const errorMessageMaybe = findError();
        setErrorMessage(errorMessageMaybe);

        if (errorMessageMaybe) {
            return;
        }

        const booking = {
            id: initialId || uuidv4(),
            title,
            description,
            roomId,
            equipmentIds
        };

        addBooking(booking);
    }

    function handleTitleChange(event) {
        setTitle(event.target.value);
    }

    function handleDescriptionChange(event) {
        setDescription(event.target.value);
    }

    function handleRoomChange(event) {
        setEquipmentIds([]);
        setRoomId(event.target.value);
    }

    const equipmentForRoomId = equipment.indexes.roomId.get(roomId) || [];
    const equipmentForRoomIdOptions = equipmentForRoomId.map(tool => ({
        id: tool.id,
        title: tool.title
    }));

    return (
        <BlModal title="Create booking" show={show} onHide={handleCloseModal}>
            <Form onSubmit={handleAddBooking}>
                <BlModalBody>
                <Col sm="12">
                    <FormGroup controlId="title" label="Title" type="text" value={title} onChange={handleTitleChange}/>
                </Col>
                <Col sm="12">
                    <FormGroup controlId="description" label="Description" as="textarea" rows="5" value={description} onChange={handleDescriptionChange}/>
                </Col>
                <Col sm="12">
                    <BlFormGroup>
                        <BlFormLabel>Room</BlFormLabel>
                        <BlFormSelect
                            value={roomId}
                            options={nonBookedRoomOptions}
                            onChange={handleRoomChange}
                        />
                    </BlFormGroup>
                </Col>
                <Col sm="12">
                    <BlFormGroup>
                        <BlFormLabel>Equipment</BlFormLabel>
                        <BlMultiSelect
                            selectedIds={equipmentIds}
                            setSelectedIds={setEquipmentIds}
                            options={equipmentForRoomIdOptions}
                            style={{ border: 'none' }}/>
                    </BlFormGroup>
                </Col>
                <BlErrorAlert errorMessage={errorMessage}/>
                </BlModalBody>
                <BlModalFooter>
                    <BlFormPrimaryButton type="submit">
                        Add
                    </BlFormPrimaryButton>
                </BlModalFooter>
            </Form>
        </BlModal>
    )
}

export default BookingModal;