import { BarChart } from '@mui/x-charts/BarChart';
import { useChartData } from 'store/selectors';
import H3 from 'components/text/H3';

function RoomUsageChart() {
    const chartData = useChartData();
    const roomUsage = chartData.roomUsage;
    
    const valueFormatter = (value) => `${value}`;


    return (
        <div style={{marginBottom: '3em'}}>
            <H3>Room usage</H3>
            <BarChart
                dataset={roomUsage}
                yAxis={[{ scaleType: 'band', dataKey: 'roomTitle' }]}
                series={[{ dataKey: 'usages', label: 'Usages', valueFormatter }]}
                layout='horizontal'
                height={400}/>
        </div>
    );
}

export default RoomUsageChart;