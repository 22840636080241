
import { useDispatch } from "react-redux";
import ToolModal from "./ToolModal";
import Actions from "store/Actions";
import { useAccountInfo, useEditToolModal } from "store/selectors";
import { updateEquipment } from "api/equipment";

function EditToolModal() {
    const dispatch = useDispatch();
    const accountInfo = useAccountInfo();
    const editToolModal = useEditToolModal();

    function addTool(tool) {
        updateEquipment(accountInfo, tool)
            .then(result => dispatch({ type: Actions.EDIT_EQUIPMENT, item: tool }));
    }

    const initialRoomInfos = editToolModal.tool.rooms.map(room => ({...room}));

    return (
        <ToolModal
            show={editToolModal.status.open}
            initialId={editToolModal.tool.id}
            initialTitle={editToolModal.tool.title}
            initialDescription={editToolModal.tool.description}
            initialRoomInfos={initialRoomInfos}
            handleTool={addTool}
            closeAction={Actions.CLOSE_EDIT_TOOL_MODAL}/>
    );
}

export default EditToolModal;