import axios from 'axios';
import Urls from 'constants/Urls';
import { createRequestContext } from './utils';


export async function createBooking(accountInfo, {id, title, description, roomId, equipmentIds}) {
    let formData = new FormData();
    formData.append('id', id);
    formData.append('title', title);
    formData.append('description', description);
    formData.append('roomId', roomId);
    formData.append('equipmentIds', JSON.stringify(equipmentIds));

    return axios.postForm(`${Urls.DJANGO}/api/${accountInfo.info.id}/booking`, formData, createRequestContext(accountInfo))
        .then(result => result.data); 
}


export async function completeBooking(accountInfo, {id}) {
    return axios.post(`${Urls.DJANGO}/api/${accountInfo.info.id}/booking/${id}`, {}, createRequestContext(accountInfo));
}


export async function fetchBookings(accountInfo) {
    return axios.get(`${Urls.DJANGO}/api/${accountInfo.info.id}/booking`, createRequestContext(accountInfo))
        .then(response => response.data);
}
