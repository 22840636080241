import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
    Container,
    Row,
    Col
} from 'react-bootstrap';
import RoomUsageChart from "./RoomUsageChart";
import ToolUsageChart from './ToolUsageChart';
import Actions from 'store/Actions';
import { fetchChartData } from 'api/chartData';
import { useAccountInfo } from 'store/selectors';


function UsageCharts() {
    const dispatch = useDispatch();
    const accountInfo = useAccountInfo();

    useEffect(() => {
        fetchChartData(accountInfo)
            .then(chartData => dispatch({ type: Actions.SET_CHART_DATA, chartData }))
    // eslint-disable-next-line
    }, []);

    return (
        <Container>
            <Row>
                <Col sm="12">
                    <RoomUsageChart/>
                </Col>
            </Row>
            <Row>
                <Col sm="12">
                    <ToolUsageChart/>
                </Col>
            </Row>
        </Container>
    )
}

export default UsageCharts;