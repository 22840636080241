function WorkspaceBody(props) {
    return (
        <div className="col py-3" style={{
            marginTop: '3em',
            marginLeft: '30%'
        }}>
            {props.children}
        </div>
    )
}

export default WorkspaceBody;