import { combineReducers } from '@reduxjs/toolkit'
import Actions from 'store/Actions';

const initialModalState = {
    status: {
        open: false
    }
}

const initialEditToolModalState = {
    status: {
        open: false
    },
    tool: {
        id: undefined,
        title: undefined,
        description: undefined,
        rooms: []
    }
}

const initialEditRoomModalState = {
    status: {
        open: false
    },
    room: {
        id: undefined,
        title: undefined,
        description: undefined
    }
}

const initialEditBookingState = {
    status: {
        open: false
    },
    booking: {
        title: undefined,
        description: undefined,
        roomId: undefined,
        itemIds: []
    }
}

const initialEditUserState = {
    status: {
        open: false
    },
    user: {
        name: undefined,
        email: undefined,
        admin: false
    }
}


function modalWithState(open) {
    return {status: {open}}
}

function signIn(state = initialModalState, action) {
    switch(action.type) {
        case Actions.OPEN_SIGN_IN_MODAL:
            return modalWithState(true);
        case Actions.CLOSE_SIGN_IN_MODAL:
            return modalWithState(false);
        default:
            return state;
    }
}

function signUp(state = initialModalState, action) {
    switch(action.type) {
        case Actions.OPEN_SIGN_UP_MODAL:
            return modalWithState(true);
        case Actions.CLOSE_SIGN_UP_MODAL:
            return modalWithState(false);
        default:
            return state;
    }
}

function addRoom(state = initialModalState, action) {
    switch(action.type) {
        case Actions.OPEN_ADD_ROOM_MODAL:
            return modalWithState(true);
        case Actions.CLOSE_ADD_ROOM_MODAL:
            return modalWithState(false);
        default:
            return state;
    }
}

function addUser(state = initialModalState, action) {
    switch(action.type) {
        case Actions.OPEN_ADD_USER_MODAL:
            return modalWithState(true);
        case Actions.CLOSE_ADD_USER_MODAL:
            return modalWithState(false);
        default:
            return state;
    }
}

function editRoom(state = initialEditRoomModalState, action) {
    switch(action.type) {
        case Actions.OPEN_EDIT_ROOM_MODAL:
            return {
                ...modalWithState(true),
                room: action.item
            };
        case Actions.CLOSE_EDIT_ROOM_MODAL:
            return {
                ...state,
                ...modalWithState(false)
            };
        default:
            return state;
    }
}

function addTool(state = initialModalState, action) {
    switch(action.type) {
        case Actions.OPEN_ADD_TOOL_MODAL:
            return modalWithState(true);
        case Actions.CLOSE_ADD_TOOL_MODAL:
            return modalWithState(false);
        default:
            return state;
    }
}

function editTool(state = initialEditToolModalState, action) {
    switch(action.type) {
        case Actions.OPEN_EDIT_TOOL_MODAL:
            return {
                ...modalWithState(true),
                tool: action.item
            };
        case Actions.CLOSE_EDIT_TOOL_MODAL:
            return {
                ...state,
                ...modalWithState(false)
            };
        default:
            return state;
    }
}

function addBooking(state = initialModalState, action) {
    switch(action.type) {
        case Actions.OPEN_ADD_BOOKING_MODAL:
            return modalWithState(true);
        case Actions.CLOSE_ADD_BOOKING_MODAL:
            return modalWithState(false);
        default:
            return state;
    }
}

function editBooking(state = initialEditBookingState, action) {
    switch(action.type) {
        case Actions.OPEN_EDIT_BOOKING_MODAL:
            return {
                ...modalWithState(true),
                booking: action.item
            };
        case Actions.CLOSE_EDIT_BOOKING_MODAL:
            return {
                ...state,
                ...modalWithState(false)
            };
        default:
            return state;
    }
}

function editUser(state = initialEditUserState, action) {
    switch(action.type) {
        case Actions.OPEN_EDIT_USER_MODAL:
            return {
                ...modalWithState(true),
                user: action.item
            };
        case Actions.CLOSE_EDIT_USER_MODAL:
            return {
                ...state,
                ...modalWithState(false)
            };
        default:
            return state;
    }
}

const modals = combineReducers({
    signIn,
    signUp,
    addRoom,
    editRoom,
    addTool,
    addUser,
    editTool,
    addBooking,
    editBooking,
    editUser
})

export default modals;