import AddRoomModal from "./pages/rooms/component/modals/AddRoomModal";
import AddToolModal from "./pages/equipment/components/modals/AddToolModal";
import EditToolModal from "./pages/equipment/components/modals/EditToolModal";
import EditRoomModal from "./pages/rooms/component/modals/EditRoomModal";
import AddBookingModal from "./pages/startWorking/components/modals/AddBookingModal";

function WorkspaceContainer(props) {
    return (
        <div>
            <div className="container-fluid">
                <div className="row flex-nowrap">
                    {props.children}
                </div>
            </div>
            <AddRoomModal/>
            <EditRoomModal/>
            <AddToolModal/>
            <EditToolModal/>
            <AddBookingModal/>
        </div>
    )

}

export default WorkspaceContainer;