import Button from 'react-bootstrap/Button';
import Colors from 'constants/Colors';

import pen from 'img/pen.svg';

function EditComponentButton({onClick}) {
    return (
        <Button className='btn-close' onClick={onClick} style={{
            background: 'none',
            border: 'none',
            boxShadow: 'none'
        }}>
           <img src={pen} alt="EditObject" style={{width: '1em', color: Colors.DARK_GREEN}}/>
        </Button>
    );
}

export default EditComponentButton;