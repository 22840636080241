import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";

import { useAccountInfo, useEquipment } from "store/selectors";
import ToolView from "./components/ToolView";
import AddToolButton from "./components/AddToolButton";
import BlPageHeader from "components/common/BlPageHeader";

import Actions from "store/Actions";
import { fetchEquipment } from "api/equipment";
import { fetchRooms } from "api/rooms";

function EquipmentContent() {
    const dispatch = useDispatch();
    const equipment = useEquipment();
    const accountInfo = useAccountInfo();

    useEffect(() => {
        fetchEquipment(accountInfo)
            .then(result => dispatch({
                type: Actions.SET_EQUIPMENT,
                items: result.equipment
            }));
        
        fetchRooms(accountInfo)
            .then(result => dispatch({
                type: Actions.SET_ROOMS,
                items: result.rooms
            }));
    
     // eslint-disable-next-line
    }, []);

    return (
        <Container>
            <BlPageHeader>Equipment</BlPageHeader>
            <Row>
                {equipment.items.map(tool => 
                    <Col key={`tool-view-${tool.id}`} sm="12" md="6" lg="4" style={{marginBottom: '1em'}}>
                        <ToolView tool={tool}/>
                    </Col> 
                )}
            </Row>
            <AddToolButton/>
        </Container>
    )
}

export default EquipmentContent;