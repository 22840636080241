import { useState, useRef } from 'react';

import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import Colors from 'constants/Colors';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';

import trashBin from 'img/trash_bin.svg';

function DeleteComponentButton({onClick}) {
    const [showConfirmation, setShowConfirmation] = useState(false);
    const target = useRef(null);

    function toggleConfirmation() {
        setShowConfirmation(!showConfirmation);
    }

    return (
        <>
            <Button ref={target} className='btn-close' onClick={toggleConfirmation} style={{
                background: 'none',
                border: 'none',
                boxShadow: 'none'
            }}>
            <img src={trashBin} alt="DeleteObject" style={{width: '1em', color: Colors.DARK_GREEN}}/>
            </Button>
            <Overlay target={target.current} show={showConfirmation} placement="top">
                {(props) => 
                    <Tooltip id="button-tooltip" {...props}>
                        <span>Are you sure?</span><br/>
                        <ButtonGroup>
                            <Button variant="primary" size="sm" onClick={onClick}>Yes</Button>
                            <Button variant="secondary" size="sm" onClick={toggleConfirmation}>No</Button>
                        </ButtonGroup>
                    </Tooltip>
                }
            </Overlay>
        </>
    );
}

export default DeleteComponentButton;