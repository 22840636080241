import { useDispatch } from 'react-redux';

import BlButton from 'components/common/buttons/BlButton';
import Actions from 'store/Actions';


function RegisterButton() {
    const dispatch = useDispatch();

    function handleRegister() {
        dispatch({ type: Actions.OPEN_SIGN_UP_MODAL })
    }

    return <BlButton 
            onClick={handleRegister}
            style={{
                fontSize: '16'
            }}>REGISTER</BlButton>
}

export default RegisterButton;