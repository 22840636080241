import { useDispatch } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Form from 'react-bootstrap/Form';

import logo from 'img/logo.svg'
import Colors from 'constants/Colors';
import BlButton from 'components/common/buttons/BlButton';
import Actions from 'store/Actions';
import BlNavLink from 'components/common/BlNavLink';


function BlNavbar() {
    const dispatch = useDispatch();

    function handleGetStarted() {
        dispatch({ type: Actions.OPEN_SIGN_IN_MODAL })
    }

    return (
        <Navbar fixed="top" expand="sm" className="justify-content-between" 
            style={{
                backgroundColor: Colors.BACKGDOUND_GRADIENT_TOP,
                color: Colors.DARK_GREEN,
                borderBottom: `0.1em solid ${Colors.BORDER}`
            }}>
            <Container fluid>
                <Navbar.Brand><img src={logo} alt="Logo" /></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Form className="d-flex" style={{marginRight: '5em'}}>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <BlNavLink href="#about">About</BlNavLink>
                            <BlNavLink href="#features">Features</BlNavLink>
                            <BlNavLink href="#pricing">Pricing</BlNavLink>
                            <BlNavLink href="#team">Team</BlNavLink>
                        </Nav>
                        <BlButton 
                            onClick={handleGetStarted}
                            style={{
                                marginLeft:'5em',
                                paddingTop: '0.5em',
                                paddingBottom: '0.5em'
                            }}>
                            Get started
                        </BlButton>
                    </Navbar.Collapse>
                </Form>
            </Container>
        </Navbar>
    );
}

export default BlNavbar;