import Colors from 'constants/Colors';
import logo from 'img/footer_logo.svg';
import instagram from 'img/instagram.svg';
import youtube from 'img/youtube.svg';
import facebook from 'img/facebook.svg';

function InstagramLink() {
    return <a href="https://instagram.com/"><img src={instagram} alt="Instagram"/></a>
}

function YouTubeLink() {
    return <a href="https://youtube.com/"><img src={youtube} alt="YouTube"/></a>
}

function FacebookLink() {
    return <a href="https://facebook.com/"><img src={facebook} alt="FaceBook"/></a>
}

function SocialLinks() {
    return <div style={{display: 'flex', justifyContent: 'space-between', width: '7em'}}>
        <InstagramLink/>
        <YouTubeLink/>
        <FacebookLink/>
    </div>
}


function MainPageFooter() {
    return <footer style={{
            backgroundColor: Colors.DARK_GREEN,
            color: 'white',
            paddingTop: '3em',
            paddingBottom: '3em',
            paddingLeft: '1em',
            marginBottom: 0,
        }}>
        <section>
            <img src={logo} alt="Logo" />
            <span style={{marginLeft: '2em'}}>Management system</span>
            <br/>
            <span>Copyright © 2024. All rights reserved</span>
            <br/>
            <span>Chermoshentseva Anastasiia</span>
            <br/>
            <span>BC-03 FBMI</span>
            <br/>
            <span>
                <SocialLinks/>
            </span>
        </section>
    </footer>
}

export default MainPageFooter;